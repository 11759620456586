export const hoverHeight = 34;
export const hoverWidth = 56;

export const hoverZones = [
    // BEGIN woods
    { x: 0, y: 0, height: 1, width: 1, zone: 'woods' },
    { x: 0, y: 1, height: 1, width: 1, zone: 'woods' },
    { x: 0, y: 2, height: 1, width: 1, zone: 'woods' },
    { x: 0, y: 3, height: 1, width: 1, zone: 'woods' },
    { x: 0, y: 4, height: 1, width: 1, zone: 'woods' },
    { x: 1, y: 0, height: 1, width: 1, zone: 'woods' },
    { x: 1, y: 1, height: 1, width: 1, zone: 'swamp' },
    { x: 1, y: 2, height: 1, width: 1, zone: 'woods' },
    { x: 1, y: 3, height: 1, width: 1, zone: 'woods' },
    { x: 1, y: 4, height: 1, width: 1, zone: 'woods' },
    { x: 2, y: 0, height: 1, width: 1, zone: 'woods' },
    { x: 2, y: 1, height: 1, width: 1, zone: 'woods' },
    { x: 2, y: 2, height: 1, width: 1, zone: 'woods' },
    { x: 2, y: 3, height: 1, width: 1, zone: 'woods' },
    { x: 2, y: 4, height: 1, width: 1, zone: 'woods' },
    { x: 3, y: 0, height: 1, width: 1, zone: 'woods' },
    { x: 3, y: 1, height: 1, width: 1, zone: 'woods' },
    { x: 3, y: 2, height: 1, width: 1, zone: 'woods' },
    { x: 3, y: 3, height: 1, width: 1, zone: 'woods' },
    { x: 3, y: 4, height: 1, width: 1, zone: 'woods' },
    { x: 4, y: 0, height: 1, width: 1, zone: 'woods' },
    { x: 4, y: 1, height: 1, width: 1, zone: 'woods' },
    { x: 4, y: 2, height: 1, width: 1, zone: 'woods' },
    { x: 4, y: 3, height: 1, width: 1, zone: 'woods' },
    { x: 4, y: 4, height: 1, width: 1, zone: 'woods' },
    //END woods
    //BEGIN MOUNTAINS
    { x: 5, y: 0, height: 1, width: 1, zone: 'mountain' },
    { x: 5, y: 1, height: 1, width: 1, zone: 'mountain' },
    { x: 5, y: 2, height: 1, width: 1, zone: 'mountain' },
    { x: 5, y: 3, height: 1, width: 1, zone: 'mountain' },
    { x: 6, y: 0, height: 2, width: 1, zone: 'temple' },
    { x: 6, y: 2, height: 1, width: 1, zone: 'mountain' },
    { x: 6, y: 3, height: 1, width: 1, zone: 'mountain' },
    { x: 7, y: 0, height: 1, width: 1, zone: 'mountain' },
    { x: 7, y: 1, height: 1, width: 1, zone: 'mountain' },
    { x: 7, y: 2, height: 1, width: 1, zone: 'mountain' },
    { x: 7, y: 3, height: 1, width: 1, zone: 'mountain' },
    { x: 8, y: 0, height: 1, width: 1, zone: 'mountain' },
    { x: 8, y: 1, height: 1, width: 1, zone: 'mountain' },
    { x: 8, y: 2, height: 1, width: 1, zone: 'mountain' },
    { x: 8, y: 3, height: 1, width: 1, zone: 'mountain' },
    { x: 9, y: 0, height: 1, width: 1, zone: 'mountain' },
    { x: 9, y: 1, height: 1, width: 1, zone: 'mountain' },
    { x: 9, y: 2, height: 1, width: 1, zone: 'mountain' },
    { x: 9, y: 3, height: 1, width: 1, zone: 'mountain' },
    //END MOUNTAINS
    //BEGIN OVERWORLD
    { x: 0, y: 5, height: 1, width: 1, zone: 'overworld' },
    { x: 0, y: 6, height: 1, width: 1, zone: 'overworld' },
    { x: 0, y: 7, height: 1, width: 1, zone: 'overworld' },
    { x: 0, y: 8, height: 1, width: 1, zone: 'overworld' },
    { x: 0, y: 9, height: 1, width: 1, zone: 'overworld' },
    { x: 1, y: 5, height: 1, width: 1, zone: 'overworld' },
    { x: 1, y: 6, height: 1, width: 1, zone: 'overworld' },
    { x: 1, y: 7, height: 1, width: 1, zone: 'overworld' },
    { x: 1, y: 8, height: 1, width: 1, zone: 'overworld' },
    { x: 1, y: 9, height: 1, width: 1, zone: 'overworld' },
    { x: 2, y: 5, height: 1, width: 1, zone: 'overworld' },
    { x: 2, y: 6, height: 1, width: 1, zone: 'overworld' },
    { x: 2, y: 7, height: 1, width: 1, zone: 'overworld' },
    { x: 2, y: 8, height: 1, width: 1, zone: 'overworld' },
    { x: 2, y: 9, height: 1, width: 1, zone: 'overworld' },
    { x: 3, y: 5, height: 1, width: 1, zone: 'overworld' },
    { x: 3, y: 6, height: 1, width: 1, zone: 'overworld' },
    { x: 3, y: 7, height: 1, width: 1, zone: 'overworld' },
    { x: 3, y: 8, height: 1, width: 1, zone: 'overworld' },
    { x: 3, y: 9, height: 1, width: 1, zone: 'overworld' },
    { x: 4, y: 5, height: 1, width: 1, zone: 'overworld' },
    { x: 4, y: 8, height: 1, width: 1, zone: 'overworld' },
    { x: 4, y: 9, height: 1, width: 1, zone: 'overworld' },
    { x: 5, y: 6, height: 1, width: 1, zone: 'overworld' },
    { x: 5, y: 7, height: 2, width: 2, zone: 'lake' },
    { x: 5, y: 9, height: 1, width: 1, zone: 'overworld' },
    { x: 6, y: 6, height: 1, width: 1, zone: 'overworld' },
    { x: 6, y: 9, height: 1, width: 1, zone: 'overworld' },
    //END OVERWORLD
    //BEGIN KAKARIKO VILLAGE
    { x: 4, y: 6, height: 2, width: 1, zone: 'village' },
    //END KAKARIKO VILLAGE
    //BEGIN HYRULE CASTLE
    { x: 5, y: 4, height: 2, width: 2, zone: 'castle' },
    //END HYRULE CASTLE
    //BEGIN VALLEY
    { x: 7, y: 4, height: 1, width: 1, zone: 'valley' },
    { x: 7, y: 5, height: 1, width: 1, zone: 'valley' },
    { x: 7, y: 6, height: 1, width: 1, zone: 'valley' },
    { x: 7, y: 7, height: 1, width: 1, zone: 'valley' },
    { x: 7, y: 8, height: 1, width: 1, zone: 'valley' },
    { x: 7, y: 9, height: 1, width: 1, zone: 'valley' },
    { x: 8, y: 4, height: 1, width: 1, zone: 'valley' },
    { x: 8, y: 5, height: 1, width: 1, zone: 'valley' },
    { x: 8, y: 6, height: 1, width: 1, zone: 'valley' },
    { x: 8, y: 7, height: 1, width: 1, zone: 'valley' },
    { x: 8, y: 8, height: 2, width: 2, zone: 'ruins' },
    { x: 9, y: 4, height: 1, width: 1, zone: 'valley' },
    { x: 9, y: 5, height: 1, width: 1, zone: 'valley' },
    { x: 9, y: 6, height: 1, width: 1, zone: 'valley' },
    { x: 9, y: 7, height: 1, width: 1, zone: 'valley' },
    //END VALLEY
]