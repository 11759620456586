const mapData = [
    { 
        zone: 'overworld',
        objects: '....................'
               + '...r+f..............'
               + '.....,..............'
               + '...T...........n....'
               + 't...o..tnnt....n....'
               + '..nn..nnnnnn........'
               + '..nn..BnnnnB........'
               + '...+...tnnt...^.....'
               + '..nn................'
               + '....................'
               + 'T.T.T...T.T.T.T.T.T.'
               + '....................',
        ground: '....................'
              + '.......,,,\\...wwww..'
              + '.......,,,,\\..ww....'
              + '..........,,\\.......'
              + '..........,,,,,,....'
              + '..........,,,,,,\\...'
              + '...............,,...'
              + '............/,,,/...'
              + '............\\,,/....'
              + '....................'
              + '....................'
              + '....................',
        elevation: '11111111111111111111'
                 + '11111122221111111111'
                 + '11111111221111111111'
                 + '11111111221111111111'
                 + '11111123333211111111'
                 + '11111133333312222111'
                 + '11111133333312222211'
                 + '11111113333112222211'
                 + '11111111111111111111'
                 + '11111111111111111111'
                 + '11111111111111111111'
                 + '11111111111111111111'
    }
];

export default mapData;